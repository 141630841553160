import Axios from '@/api/apiConfig/axios';

/**
 *登录
 *
 * @param {string}
 * @param {string}
 *
 * @return {Object} 返回值描述
 */
export const login = async (req: any) => {
  try {
    return await Axios.request({
      url: `/consumer/get/token?code=${req.code}`,
      method: 'GET'
    });
  } catch (e) {
    throw e; // 抛出异常
  }
};

// /consumer/info
/**
 *登录
 *
 * @param {string}
 * @param {string}
 *
 * @return {Object} 返回值描述
 */
export const currentUserInfo = async (req: any) => {
  try {
    return await Axios.request({
      url: `/consumer/info?openId=${req.openId}`,
      method: 'GET'
    });
  } catch (e) {
    throw e; // 抛出异常
  }
};

export const sendSms = async (phone: any) => {
  try {
    return await Axios.request({
      url: `/consumer/verificationCode?phone=${phone}`,
      method: 'GET'
    });
  } catch (e) {
    throw e; // 抛出异常
  }
};

// /consumer/register
export const registryConsumer = async (payload: any) => {
  try {
    return await Axios.request({
      url: `/consumer/register`,
      data: payload,
      method: 'POST',
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
  } catch (e) {
    throw e; // 抛出异常
  }
};
