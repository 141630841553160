import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';
import '@/utils/rem';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import store, { history } from '@/store';
import { Provider } from 'react-redux';
import * as serviceWorker from './serviceWorker';
import { ConnectedRouter } from 'connected-react-router';
import './index.scss';
import '@/styles/normalize.css';
import '@/styles/global.scss';
import { message } from 'antd';
import VConsole from 'vconsole';

if (process.env.REACT_APP_ENV !== 'production') {
  new VConsole();
}

/* 全局配置message 参数 */
message.config({
  top: 40, // 距离顶部的位置
  duration: 1.5, // 自动关闭延时
  maxCount: 1 // 最大显示数，最多同时显示1个message
});

ReactDOM.render(
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <App />
    </ConnectedRouter>
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
