import Axios from '@/api/apiConfig/axios';

/**
 *登录
 *
 * @param {string}
 * @param {string}
 *
 * @return {Object} 返回值描述
 */
export const wechatConfig = async (req: any) => {
  try {
    return await Axios.request({
      url: `/consumer/wechat/config?url=${req.url}`,
      method: 'GET'
    });
  } catch (e) {
    throw e; // 抛出异常
  }
};

// /qrcode/scan
/**
 * 兑换积分
 *
 * @param {string}
 * @param {string}
 *
 * @return {Object} 返回值描述
 */
export const qrcodeScan = async (req: any) => {
  try {
    return await Axios.request({
      url: `/qrcode/scan`,
      method: 'POST',
      data: req
    });
  } catch (e) {
    throw e; // 抛出异常
  }
};

// /qrcode/search
export const qrcodeSearch = async (req: any) => {
  try {
    return await Axios.request({
      url: `/qrcode/search?number=${req.number}`,
      method: 'GET'
    });
  } catch (e) {
    throw e; // 抛出异常
  }
};

// /qrcode/point
export const pointSearch = async (req: any) => {
  try {
    return await Axios.request({
      url: `/qrcode/point?number=${req.number}`,
      method: 'GET'
    });
  } catch (e) {
    throw e; // 抛出异常
  }
};
