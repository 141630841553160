import React from 'react';

export const Loading = () => {
  return (
    <p
      style={{
        textAlign: 'center',
        height: '1.0rem',
        lineHeight: '1.0rem',
        color: '#999',
        fontSize: '0.24rem'
      }}
    >
      <b>加载中...</b>
    </p>
  );
};

export const NoMore = () => {
  return (
    <p
      style={{
        textAlign: 'center',
        height: '1.0rem',
        lineHeight: '1.0rem',
        color: '#999',
        fontSize: '0.24rem'
      }}
    >
      <b>我是有底线的</b>
    </p>
  );
};
