import React from 'react';
import classnames from 'classnames';
import Footer from '@/components/Footer';
import styles from './RegistryLayout.module.scss';

const RegistryLayout = (props: any) => {
  return (
    <div className={classnames(styles['basic-layout-wrap'], 'flex', 'flex-v')}>
      <div className="flex-1">{props.children}</div>
      <Footer />
    </div>
  );
};

export default RegistryLayout;
