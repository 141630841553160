import React from 'react';
import ReactDOM from 'react-dom';
import * as antdMobile from 'antd-mobile';
import { GoodsCard } from './pointsMall/index';
import { mallList } from '@/api/apiServer/apiProfile';
import classname from 'classnames';

function MyBody(props) {
  return <div className="am-list-body my-body">{props.children}</div>;
}

let data = [];
let NUM_SECTIONS = 1;
let NUM_ROWS_PER_SECTION = 5;
let pageIndex = 0;

let dataBlobs = {};
let sectionIDs = [];
let rowIDs = [];

const getMallList = (resolve, reject) => {
  mallList({
    type: 1,
    pageNum: pageIndex,
    pageSize: 10
  })
    .then(({ data: _data }) => {
      console.log(_data);
      if (_data.code === '00000000') {
        let result = [];
        for (let x = 0; x < Math.ceil(_data.data.length / 2); x++) {
          let start = x * 2;
          let end = start + 2;
          result.push(_data.data.slice(start, end));
        }
        console.log(result);
        data = data.concat(result);
        resolve(data);
      } else {
        reject('error');
      }
    })
    .catch(e => {
      reject(e);
    });
};

function genData(pIndex = 0) {
  for (let i = 0; i < NUM_SECTIONS; i++) {
    const ii = pIndex * NUM_SECTIONS + i;
    const sectionName = `Section ${ii}`;
    sectionIDs.push(sectionName);
    dataBlobs[sectionName] = sectionName;
    rowIDs[ii] = [];

    for (let jj = 0; jj < NUM_ROWS_PER_SECTION; jj++) {
      const rowName = `S${ii}, R${jj}`;
      rowIDs[ii].push(rowName);
      dataBlobs[rowName] = rowName;
    }
  }
  sectionIDs = [...sectionIDs];
  rowIDs = [...rowIDs];
}

export default class Demo extends React.Component {
  constructor(props) {
    super(props);
    const getSectionData = (dataBlob, sectionID) => dataBlob[sectionID];
    const getRowData = (dataBlob, sectionID, rowID) => dataBlob[rowID];

    const dataSource = new antdMobile.ListView.DataSource({
      getRowData,
      getSectionHeaderData: getSectionData,
      rowHasChanged: (row1, row2) => row1 !== row2,
      sectionHeaderHasChanged: (s1, s2) => s1 !== s2
    });

    this.state = {
      dataSource,
      isLoading: true,
      height: (document.documentElement.clientHeight * 3) / 4
    };
  }

  componentDidMount() {
    // you can scroll to the specified position
    // setTimeout(() => this.lv.scrollTo(0, 120), 800);

    const hei =
      document.documentElement.clientHeight - ReactDOM.findDOMNode(this.lv).parentNode.offsetTop;
    // simulate initial Ajax
    setTimeout(() => {
      new Promise((resolve, reject) => {
        getMallList(resolve, reject);
      }).then(res => {
        genData();
        this.setState({
          dataSource: this.state.dataSource.cloneWithRowsAndSections(dataBlobs, sectionIDs, rowIDs),
          isLoading: false,
          height: hei
        });
      });
    }, 600);
  }

  // If you use redux, the data maybe at props, you need use `componentWillReceiveProps`
  // componentWillReceiveProps(nextProps) {
  //   if (nextProps.dataSource !== this.props.dataSource) {
  //     this.setState({
  //       dataSource: this.state.dataSource.cloneWithRowsAndSections(nextProps.dataSource),
  //     });
  //   }
  // }

  onEndReached = event => {
    // load new data
    // hasMore: from backend data, indicates whether it is the last page, here is false
    if (this.state.isLoading && !this.state.hasMore) {
      return;
    }
    console.log('reach end', event);
    this.setState({ isLoading: true });
    setTimeout(() => {
      genData(++pageIndex);
      this.setState({
        dataSource: this.state.dataSource.cloneWithRowsAndSections(dataBlobs, sectionIDs, rowIDs),
        isLoading: false
      });
    }, 1000);
  };

  render() {
    let index = data.length - 1;
    const row = (rowData, sectionID, rowID) => {
      if (index < 0) {
        index = data.length - 1;
      }
      const obj = data[index--];
      console.log('obj:', obj);
      return (
        <div key={rowID} className={classname('flex', 'flex-align-center', 'flex-pack-justify')}>
          {obj.map(item => (
            <GoodsCard key={item.id} data={item} />
          ))}
        </div>
      );
    };

    return (
      <antdMobile.ListView
        ref={el => (this.lv = el)}
        dataSource={this.state.dataSource}
        renderFooter={() => (
          <div style={{ padding: 30, textAlign: 'center' }}>
            {this.state.isLoading ? 'Loading...' : 'Loaded'}
          </div>
        )}
        renderBodyComponent={() => <MyBody />}
        renderRow={row}
        style={{
          height: this.state.height,
          overflow: 'auto'
        }}
        pageSize={4}
        onScroll={() => {
          console.log('scroll');
        }}
        scrollRenderAheadDistance={500}
        onEndReached={this.onEndReached}
        onEndReachedThreshold={10}
      />
    );
  }
}
