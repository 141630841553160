import layouts from '@/layouts/index';
import { lazy } from 'react';
import { MenuBase } from './config';
const Profile = lazy(() => import('@/pages/profile'));
const PointsMall = lazy(() => import('@/pages/pointsMall'));
const ExchangeGoods = lazy(() => import('@/pages/pointsMall/ExchangeGoods'));
const ExchangeAwardGoods = lazy(() => import('@/pages/pointsMall/ExchangeAwardGoods'));
const CashCoupons = lazy(() => import('@/pages/cashCoupons'));
const UseCashCoupons = lazy(() => import('@/pages/cashCoupons/used'));
const InvalidCashCoupons = lazy(() => import('@/pages/cashCoupons/invalid'));
const ExchangeCashCoupons = lazy(() => import('@/pages/cashCoupons/exchange'));
const DestroyCashCoupons = lazy(() => import('@/pages/cashCoupons/destroy'));
const PointsBill = lazy(() => import('@/pages/pointsBill'));
const Order = lazy(() => import('@/pages/order'));
const Turntable = lazy(() => import('@/pages/turntable'));
const WinningRecord = lazy(() => import('@/pages/turntable/winningRecord'));
const Message = lazy(() => import('@/pages/message'));
const WithDraw = lazy(() => import('@/pages/withdraw'));
const WithDrawInput = lazy(() => import('@/pages/withdraw/input'));
const BalanceRecord = lazy(() => import('@/pages/withdraw/balanceRecord'));
const WithDrawRecord = lazy(() => import('@/pages/withdraw/withdrawRecord'));

const { BasicLayout = '' } = { ...layouts };

const profileRoutes: MenuBase[] = [
  {
    path: '/profile',
    name: '个人中心',
    exact: true,
    layout: BasicLayout,
    component: Profile
  },
  {
    path: '/pointsMall',
    name: '积分商城',
    exact: true,
    layout: BasicLayout,
    component: PointsMall
  },
  {
    path: '/pointsMall/exchange',
    name: '兑换商品',
    exact: true,
    layout: BasicLayout,
    component: ExchangeGoods
  },
  {
    path: '/pointsMall/exchangeAwardGoods',
    name: '兑换商品',
    exact: true,
    layout: BasicLayout,
    component: ExchangeAwardGoods
  },
  {
    path: '/cashCoupons',
    name: '现金券列表',
    exact: true,
    layout: BasicLayout,
    component: CashCoupons
  },
  {
    path: '/message',
    name: '消息列表',
    exact: true,
    layout: BasicLayout,
    component: Message
  },
  {
    path: '/cashCoupons/invalid',
    name: '已核销现金券列表',
    exact: true,
    layout: BasicLayout,
    component: InvalidCashCoupons
  },
  {
    path: '/cashCoupons/used',
    name: '使用现金券',
    exact: true,
    layout: BasicLayout,
    component: UseCashCoupons
  },
  {
    path: '/cashCoupons/exchange',
    name: '兑换现金券',
    exact: true,
    layout: BasicLayout,
    component: ExchangeCashCoupons
  },
  {
    path: '/cashCoupons/destroy',
    name: '核销现金券',
    exact: true,
    layout: BasicLayout,
    component: DestroyCashCoupons
  },
  {
    path: '/pointsBill',
    name: '积分账单',
    exact: true,
    layout: BasicLayout,
    component: PointsBill
  },
  {
    path: '/order',
    name: '我的订单',
    exact: true,
    layout: BasicLayout,
    component: Order
  },
  {
    path: '/turntable',
    name: '幸运大转盘',
    exact: true,
    layout: BasicLayout,
    component: Turntable
  },
  {
    path: '/turntable/record',
    name: '我的中奖记录',
    exact: true,
    layout: BasicLayout,
    component: WinningRecord
  },
  {
    path: '/withdraw',
    name: '提现',
    exact: true,
    layout: BasicLayout,
    component: WithDraw
  },
  {
    path: '/withdraw/input',
    name: '提现',
    exact: true,
    layout: BasicLayout,
    component: WithDrawInput
  },
  {
    path: '/withdraw/balance',
    name: '收支记录',
    exact: true,
    layout: BasicLayout,
    component: BalanceRecord
  },
  {
    path: '/withdraw/record',
    name: '提现记录',
    exact: true,
    layout: BasicLayout,
    component: WithDrawRecord
  }
];
export default profileRoutes;
